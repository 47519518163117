<template>
  <div>
    <vx-card no-shadow>
      <div id="inquiries-table-loading" class="vs-con-loading__container">
        <div class="md:flex py-2 md:py-4 w-full">
          <div class="sm:flex flex-grow items-center mb-2 md:mb-0">
            <div class="sm:mr-2 mr-0 mb-2 sm:mb-0">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span v-if="pageData.total" class="mr-2"> {{paginationInfo }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                    <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 50">
                    <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 100">
                    <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 200">
                    <span>200</span>
                </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
          <vs-input class="mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
          v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
        </div>
        <vs-collapse class="px-0 mb-4" type="border">
          <vs-collapse-item v-for="(inquiry, inIndex) in pageData.data" :key="inIndex" icon-arrow="label">
            <div slot="header">
              {{ inquiry.user.first_name }} {{ inquiry.user.last_name }}
            </div>
            <div>
              <div class="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white border border-gray-300">
                <div class="py-2 border-b">
                  <div class="font-bold py-2 border-b">User</div>
                  <span class="text-inherit hover:text-primary" @click="$router.push({path: '/users/'+inquiry.user.id })">
                    {{ inquiry.user.first_name }} {{ inquiry.user.last_name }}
                  </span>
                </div>
                <div class="py-2 border-b">
                  <div class="font-bold py-2 border-b">Product</div>
                  <div>
                    <span class="text-info cursor-pointer" @click="$router.push({path: '/products/'+inquiry.product_id})">{{inquiry.product.name}}</span>
                  </div>
                </div>
                <div class="py-2 border-b">
                  <div class="font-bold py-2 border-b">Published on</div>
                  {{inquiry.created_at | date_parse(true)}}
                </div>
                <div class="py-2 border-b">
                  <div class="font-bold py-2 border-b">Action</div>
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteItem(inquiry.id)" />
                </div>
              </div>
              <inquiry-content :inquiry="inquiry" />
            </div>
          </vs-collapse-item>
        </vs-collapse>

        <vs-pagination
        :total="pageData.last_page || 1"
        :max="7"
        v-model="currentPage" />
      </div>
    </vx-card>
  </div>
</template>
<script>

import _ from 'lodash';
import InquiryContent from '@/components/cards/InquiryContent.vue'

export default {
  components: {
    InquiryContent
  },
  data() {
    return {
      searchQuery: "",
      pageData:{
        data: []
      }
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    isReal (val) {
      this.fetchItems()
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
       of ${this.pageData.total}`
    }
  },
  methods:{
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected review will be deleted!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type:'sound'})
      this.$http.delete(`/inquiries/${itemId}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          const ItemIndex = this.pageData.data.findIndex((item) => item.id == itemId)
          this.pageData.data.splice(ItemIndex, 1)
        }
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage , is_real: this.isReal}
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#inquiries-table-loading', color: "#444", type: "sound"})
      this.$http.get(`/inquiries?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.$vs.loading.close('#inquiries-table-loading > .con-vs-loading')
        if(response.data.success){
          this.pageData = response.data.data
        }
     })
    },
  },
  mounted(){
    this.fetchItems()
  }
}
</script>

<style type="text/css">
  .vue-star-rating-star{
    height: 25px;
    width:25px;
  }
  .vs-con-textarea{
    margin-bottom: 0;
  }
</style>
