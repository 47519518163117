<template>
  <div>
    <div>
        <div class="my-3">
            <h5 class="mb-4"><strong> Question </strong></h5>
            <div v-if="inquiry.user" class="mb-3">
                <h5 class="mb-2">{{inquiry.user.first_name}}</h5>
                <div class="" v-if="inquiry.user.country">
                    <span class="flex items-center"><img style="height:15px;" class="mr-1"
                        :src="absoluteUrl()+'/images/country_flags/'+inquiry.user.country.icon" alt="star" />
                    {{inquiry.user.country.name}}</span>
                </div>
            </div>
            <p class="mb-1">{{inquiry.content}}</p>
            <small class="d-block opacity-50">Published: {{inquiry.created_at | date(true)}} </small>
        </div>
        <div class="pl-5">
            <div class="flex mb-5 justify-between">
                <h5 class="mb-0"><strong> Answers ({{inquiry.answers.length}}) </strong></h5>
                <vs-button class="ml-auto" @click="popupModalAnswer = true;" size="small">Add Answer</vs-button>
            </div>
            <div v-for="(answer, anIndex) in inquiry.answers" :key="anIndex" class="mb-5">
                <h5 class="capitalize"> {{answer.user.first_name}}</h5>
                <p class="mb-1">{{answer.content}}</p>
                <small class="d-block opacity-50">Published: {{answer.created_at | date(true)}} </small>
            </div>
        </div>
    </div>
    <vs-popup class="holamundo" @close="initializeAll"
     :title="editMode? 'Update Answer': 'Add Answer'"
     :active.sync="popupModalAnswer">
      <div class="mt-2">
        <inquiry-answer-form v-if="popupModalAnswer" :edit-item="showItem"
        @closeMe="initializeAll()" :inquiryId="inquiry.id" :edit-mode="editMode"
        @addItem="addAnswer($event)"  @updateItem="updateAnswer($event)"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import InquiryAnswerForm from '@/components/forms/InquiryAnswerForm.vue';

export default {
  components: { InquiryAnswerForm },
    props:{
        inquiry: {
            type: Object
        }
    },
    data(){
        return {
            popupModalAnswer: false,
            editMode: false,
            showItem: null,
        }
    },
    methods:{
        addAnswer(item){
            this.inquiry.answers.unshift(item)
        },
        updateAnswer(item){
            const dataIndex = this.inquiry.answers.findIndex((o) => o.id == item.id);
            Object.assign(this.inquiry.answers[dataIndex], item);
        },
        editItem(item){
            this.showItem = {...item};
            this.editMode = true
            this.popupModalAnswer = true
        },
        initializeAll(){
            this.editMode = false
            this.showItem = null
            this.popupModalAnswer = false
        }
    },
}
</script>

<style>

</style>