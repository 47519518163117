<template>
 <form>
    <div class="vx-row items-end">
        <div class="vx-col w-full">
            <div class="">
                <vs-textarea label="Write answer" v-validate="{ required: true}" rows="6" name="content" 
                    v-model="data.content" placeholder="Enter content" />
                <span class="text-danger text-sm"  v-show="errors.has('content')">{{ errors.first('content') }}</span>
            </div>
        </div>
    </div>
    <vs-button class="ml-auto mt-4" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Add answer'}}</vs-button>
</form>
</template>

<script>

export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        inquiryId: {
            type: Number,
            required: true
        }
    },
    data(){
        return{
            data: {
                content: "",
                inquiry_id: this.inquiryId
            },
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/inquiry-answers/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$emit('addItem', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/inquiry-answers/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
            this.data.start = new Date(this.data.start);
            this.data.end = new Date(this.data.end);
        }
    }
}
</script>

<style>

</style>